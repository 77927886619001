import { render, staticRenderFns } from "./website.html?vue&type=template&id=2aed3612&scoped=true&"
import script from "./website.js?vue&type=script&lang=js&"
export * from "./website.js?vue&type=script&lang=js&"
import style0 from "./website.scss?vue&type=style&index=0&id=2aed3612&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aed3612",
  null
  
)

export default component.exports